import React, {useState, useEffect} from 'react';
import {CloseCircleFilledIcon} from 'tdesign-icons-react';
import {
    Table,
    Pagination,
    Tag,
    Space,
    DateRangePicker,
    Select,
    Col,
    Row,
    Tooltip,
    Divider,
    Dropdown, MessagePlugin
} from 'tdesign-react';
import type {DateRangePickerProps} from 'tdesign-react';
import dayjs from 'dayjs';
import {getMarketListByTaskIdApi, getXhsApi, updateMarketingUserApi} from "../api/api";
import QRCode from "qrcode.react";

const IntentorTable = ({userLinkList, total, curMarketingTaskId, taskId, platform, setOptions}) => {
    const [userRegion, setUserRegion] = useState('全选');//选中的地区
    const [dateRange, setDateRange] = useState([]);//选中的日期
    const [regionOptions, setRegionOptions] = useState([]);//地区选项
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(total);//筛选的表长度
    const [userList, setUserList] = useState([]);//展示的表
    const [sumData, setSumData] = useState([]);//总表，只根据userLinkList变化
    const [isUserListUpdated, setIsUserListUpdated] = useState(false);//是否被用户手动改过意向表

    const columns = [
        {title: '意向用户列表', colKey: 'nickname', width: '200px'},
        {title: '用户地区', colKey: 'region', width: '120px'},
        {title: '评论时间', colKey: 'time', width: '120px'},
        {title: '评论内容', colKey: 'comment', width: 'auto'},
        {
            title: '是否私信', colKey: 'privateMessage',
            cell: ({row}) => {
                return (
                    row.privateMessage === '已私信' ?
                        <Tag variant="outline" theme="success">
                            <p id={row.comment_id}>已私信</p></Tag> :
                        <Dropdown
                            options={[{content: '移出意向列表', value: 1,}, {
                                content: '设置为已私信',
                                value: 2,
                            }, {content: '设置为未私信', value: 3,}]}
                            onClick={(option) => clickHandler(option, row)}>
                            <Tag variant="outline" theme="warning">
                                <p>{row.privateMessage !== null ? row.privateMessage : '未私信'}</p>
                            </Tag>
                        </Dropdown>
                )
            }
        }
    ];

    useEffect(() => {
        setOptions({"ip_location": userRegion, ...convertToTimestampRange(dateRange)})
        // eslint-disable-next-line
    }, [userRegion, dateRange]);


    //转换日期为unix时间戳
    const convertToTimestampRange = (dates) => {
        const [startDate, endDate] = dates;
        // 将日期字符串解析为 Date 对象
        const startTimestamp = new Date(`${startDate}-01T00:00:00Z`).getTime() / 1000; // 获取 start 时间的秒级时间戳
        const endTimestamp = new Date(`${endDate}-01T23:59:59Z`).getTime() / 1000;   // 获取 end 时间的秒级时间戳

        return {
            "start_time": Math.floor(startTimestamp),
            "end_time": Math.floor(endTimestamp)
        };
    }


    useEffect(() => {
        if (userLinkList && userLinkList.length !== 0) {
            // userLinkList.forEach(item=>console.log(item.私信结果))
            const formattedData = formatData(userLinkList)
            setSumData(formattedData);

            const regionCount = formattedData.reduce((acc, item) => {
                acc[item.region] = (acc[item.region] || 0) + 1;
                return acc;
            }, {});

            const sortedRegions = Object.keys(regionCount).sort((a, b) => {
                if (a === "IP未知") return 1;
                if (b === "IP未知") return -1;
                return regionCount[b] - regionCount[a];
            });

            const formattedRegions = sortedRegions.map(region => ({
                label: `${region}-${regionCount[region]}人`,
                value: region
            }));
            setRegionOptions([{label: '全部地区', value: '全选'}, ...formattedRegions]);

            setTotalItems(formattedData.length);
            const start = (currentPage - 1) * pageSize;
            const end = start + pageSize;
            setUserList(formattedData.slice(start, end));
        } else {
            setUserList([])
        }
        // eslint-disable-next-line
    }, [userLinkList]);

    useEffect(() => {//userLinkList改变时，对应修改存储的region和date range
        if (sumData.length > 0) {
            const tempUserRegion = localStorage.getItem(`${taskId}-region`) || '全选'
            const tempDateRange = JSON.parse(localStorage.getItem(`${taskId}-date`)) || []
            setUserRegion(tempUserRegion);
            setDateRange(tempDateRange)
            filterData(tempUserRegion, tempDateRange, 0, pageSize)
        }
        // eslint-disable-next-line
    }, [sumData]);

    useEffect(() => {//页码有变化立即重新获取
        if ((curMarketingTaskId && curMarketingTaskId.length > 0 && curMarketingTaskId.includes(taskId)) || isUserListUpdated) {
            getUserList()
        }
        // eslint-disable-next-line
    }, [curMarketingTaskId, currentPage, pageSize, isUserListUpdated]);

    useEffect(() => {
        setCurrentPage(1);
    }, [taskId]);

    const formatData = (data) => {//把后端的user_link_list格式化
        return data.map((item, index) => ({
            key: index,
            nickname: platform === 'xhs'
                ?
                <Tooltip content={
                    <div align={"center"}>
                        <span style={{fontSize: "18px"}}>小红书扫码私信</span>
                        <Divider layout={"horizontal"} style={{margin: "10px 5px"}}/>
                        <QRCode value={item.user_link} size={128}/></div>
                } theme="light">
                    <a href={item.user_link} target="_blank" rel="noreferrer">{item.用户昵称}</a>
                </Tooltip>
                : <a href={item.user_link} target="_blank" rel="noreferrer">{item.用户昵称}</a>,
            region:
                item.IP地址 === "" || item.IP地址 === "IP未知" ? "IP未知" : item.IP地址,
            comment: platform === 'xhs'
                ? <a href={`#${index}`} onClick={(e) => handleLinkClick(e, item.内容链接)}>{item.评论内容}</a>
                : <a href={item.内容链接} target="_blank" rel="noreferrer">{item.评论内容}</a>,
            privateMessage: item.私信结果,
            time: item.评论时间,
            comment_id: item.comment_id
        }));
    }

    //处理xhs的链接跳转
    const handleLinkClick = async (e, originalLink) => {
        e.preventDefault(); // 阻止默认的打开行为
        const match = originalLink.match(/explore\/([^/?]+)/);
        const id = match ? match[1] : null;
        if (id) {

            const response = await getXhsApi(id);
            if (response.code === 200 && response.data !== null) {
                const xhsLink = 'https://www.xiaohongshu.com/explore/' + response.data.explore + "?xsec_token=" + response.data.xsec_token + '&xsec_source=pc_search'
                window.open(xhsLink, '_blank');

            }
        } else {
            console.error("无法提取链接中的ID");
        }
    }

    const getUserList = async () => {//重新获取当前页码的userLinkList
        let offset = (currentPage - 1) * pageSize
        await getMarketListByTaskIdApi(taskId, offset, pageSize)
            .then(data => {
                if (data && data.user_link_list) {
                    const formattedData = formatData(data.user_link_list)
                    setUserList(formattedData)
                }
            }).catch(err => console.error(err))
    }

    const handlePageChange = (newPage, newPageSize) => {
        setCurrentPage(newPage);
        setPageSize(newPageSize);
        const start = (newPage - 1) * newPageSize;
        const end = start + newPageSize;
        filterData(userRegion, dateRange, start, end);
    };

    const handleRegionChange = (value) => {
        localStorage.setItem(`${taskId}-region`, value)
        setUserRegion(value);
        setCurrentPage(1);
        filterData(value, dateRange, 0, pageSize);
    };

    const handleRangeChange: DateRangePickerProps['onChange'] = (value) => {
        localStorage.setItem(`${taskId}-date`, JSON.stringify(value));
        setDateRange(value);
        setCurrentPage(1);
        filterData(userRegion, value, 0, pageSize);
    };

    const filterData = (region, range, start, end) => {
        const [startDate, endDate] = range;
        const filteredData = sumData.filter(item => {
            const itemDate = new Date(item.time);
            const isRegionMatch = region === '全选' || item.region === region;
            const isDateMatch = !range.length || (itemDate >= new Date(startDate) && itemDate <= new Date(endDate));
            return isRegionMatch && isDateMatch;
        });
        setTotalItems(filteredData.length);
        setUserList(filteredData.slice(start, end));
    };

    const clickHandler = async (option, row) => {//“未私信”客户的处理
        let back_data = {
            task_id: taskId,
            platform,
            comment_id: row.comment_id
        }
        if (option.value === 1) {
            back_data = {
                ...back_data,
                intent_customer: '否'
            }
        }else {
            if (option.value === 2){
                back_data = {
                    ...back_data,
                    market_result: '已私信'
                }
            }

            if (option.value === 3){
                back_data = {
                    ...back_data,
                    market_result: '未私信'
                }
            }
        }

        // console.log(option.value,back_data)
        await updateMarketingUserApi(back_data).then(data => {
            if (data.status === 200) {
                getUserList().then(data => {
                        setIsUserListUpdated(true);
                        MessagePlugin.success('操作成功')
                    }
                )
            }
        })
    }

    const customClearIcon = <CloseCircleFilledIcon style={{color: 'blue'}}/>;
    return (
        <Space direction='vertical' style={{width: "98%"}}>
            <Row direction='horizontal' align='center' style={{width: "100%"}}>
                <Col flex="none">
                    <Space align='center'>
                        {/*<h2>筛选条件</h2>*/}
                        <Select
                            style={{width: "150px"}}
                            value={userRegion}
                            options={regionOptions}
                            onChange={handleRegionChange}
                            placeholder="选择地区"
                        />
                        <DateRangePicker
                            style={{width: "15vw"}}
                            mode="month"
                            value={dateRange}
                            clearable
                            allowInput
                            onChange={handleRangeChange}
                            placeholder="时间范围"
                            suffixIcon={customClearIcon}
                            disableDate={(date) => dayjs(date).isAfter(dayjs().add(1, 'month').endOf('month'), 'day')}
                        />
                    </Space>
                </Col>
                <Col flex="auto">
                    <Pagination
                        style={{marginLeft: "10px"}}
                        total={totalItems}
                        pageSize={pageSize}
                        current={currentPage}
                        onChange={(pageInfo) => handlePageChange(pageInfo.current, pageInfo.pageSize)}
                        showJumper
                        pageSizeOptions={[10, 20]}
                    />
                </Col>
            </Row>
            <Table columns={columns} data={userList} bordered style={{width: "100%"}} rowKey={"comment_id"}/>
        </Space>
    );
};

export default IntentorTable;
